import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Form, InputGroup } from "react-bootstrap";
import { message } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { CountryData } from "../../datas/formdata";

const DetailsForm = ({
  setActive,
  onSubmit,
  submitted,
  setSubmitted,
  registerdata,
  setRegisterData,
  handleOnChange,
  domainstatus,
  setDomainSuccess,
  setDomainStatus,
  handleChangeDomain,
}) => {
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [urlloader, setUrlLoader] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [token, setToken] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [formError, setFormError] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateEmail = (value) => {
    const isValidEmail = /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      value
    );
    return isValidEmail;
  };

  const handleChangeEmail = (e) => {
    const newEmail = e.target.value;
    setRegisterData(newEmail);
    if (newEmail.length === 0) {
      setEmailError("Email is required");
    } else if (!validateEmail(newEmail)) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
    setRegisterData({ ...registerdata, email: newEmail });
  };

  // const validate = (e) => {
  //   e.preventDefault();
  //   setSubmitted(true);
  //   let hasError = false;
  //   if (registerdata.email.length === 0) {
  //     setEmailError("Email is required");
  //     hasError = true;
  //   } else if (!validateEmail(registerdata.email)) {
  //     setEmailError("Please enter a valid email");
  //     hasError = true;
  //   } else {
  //     setEmailError("");
  //   }

  //   if (
  //     !registerdata.firstName ||
  //     !registerdata.lastName ||
  //     !registerdata.phoneNumber ||
  //     !registerdata.domain ||
  //     !registerdata.employees ||
  //     !registerdata.country
  //   ) {
  //     setFormError("This field is required.");
  //     return;
  //   }
  //   if (
  //     !hasError ||
  //     !registerdata.firstName ||
  //     !registerdata.lastName ||
  //     !registerdata.phoneNumber ||
  //     !registerdata.companyName ||
  //     !registerdata.domain ||
  //     !registerdata.employees ||
  //     !registerdata.country
  //   ) {
  //     onSubmit(e);
  //   }
  // };

  const validate = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let hasError = false;

    if (registerdata.email.length === 0) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!validateEmail(registerdata.email)) {
      setEmailError("Please enter a valid email");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (
      !registerdata.firstName ||
      !registerdata.lastName ||
      !registerdata.phoneNumber ||
      !registerdata.domain ||
      !registerdata.employees ||
      !registerdata.country
    ) {
      setFormError("This field is required.");
      hasError = true;
    }

    if (!registerdata.terms) {
      setFormError("This field is required.");
      hasError = true;
    }

    if (!hasError) {
      onSubmit(e);
    }
  };

  const handleKeyDown = (event) => {
    const isNumericKey =
      (event.key >= "0" && event.key <= "9") ||
      [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
        "Tab",
      ].includes(event.key);
    const isCtrlKey = event.key === "Control" || event.ctrlKey || event.metaKey;
    if (!isNumericKey && !isCtrlKey) {
      event.preventDefault();
    }
  };
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  };

  const handleletterKeyDown = (event) => {
    const keyCode = event.keyCode || event.which;

    const isLetter =
      (keyCode >= 65 && keyCode <= 90) || // A-Z
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 32 ||
      [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
        "Tab",
      ].includes(event.key);

    if (!isLetter) {
      event.preventDefault();
    }
  };

  const [selectedCountryName, setSelectedCountryName] = useState("");

  const handleCountryChange = (event) => {
    const selectedCountryName = event.target.value;
    const selectedCountryData = CountryData.find(
      (country) => country.name === selectedCountryName
    );

    if (selectedCountryData) {
      setSelectedCountry(selectedCountryData.dial_code);
      setSelectedCountryName(selectedCountryData.name);
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        autoComplete="off"
        className=" signup-form"
        onSubmit={(e) => validate(e)}
      >
        <Row>
          <Row>
            <Col md={6} className="pb-3">
              <Form.Group>
                <Form.Label className="label-custom">First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  className="placeholder-style"
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                  autoComplete="off"
                  maxLength={15}
                  onKeyDown={handleletterKeyDown}
                  value={registerdata.firstName}
                />
              </Form.Group>
              <span className="error-msg">
                {registerdata.firstName.length === 0 ? formError : ""}
              </span>
            </Col>
            <Col md={6} className="pb-3">
              <Form.Group>
                <Form.Label className="label-custom">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  className="placeholder-style"
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                  autoComplete="off"
                  maxLength={15}
                  onKeyDown={handleletterKeyDown}
                  value={registerdata.lastName}
                />
              </Form.Group>
              <span className="error-msg">
                {registerdata.lastName.length === 0 ? formError : ""}
              </span>
            </Col>
          </Row>
          <Col md={12} className="pb-3">
            <Form.Group controlId="formBasicEmail" className="text-start">
              <Form.Label className="label-custom">Email ID</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder=""
                className="placeholder-style"
                onChange={(e) => {
                  handleChangeEmail(e);
                }}
                value={registerdata.email}
                autoComplete="off"
                maxLength={50}
              />
            </Form.Group>
            <span className="error-msg">{emailError}</span>
          </Col>
          <Col md={6} className="pb-3">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="label-custom">Country</Form.Label>
              <Form.Select
                name="country"
                onChange={(e) => {
                  handleOnChange(e);
                  handleCountryChange(e);
                }}
                autoComplete="off"
                value={registerdata.country}
              >
                <option value="">Select Country</option>
                {CountryData.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <span className="error-msg">
              {registerdata.country.length === 0 ? formError : ""}
            </span>
          </Col>
          <Col md={6}>
            <Form.Group className="pb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="label-custom">Phone Number</Form.Label>
              <InputGroup className="mb-3">
                {selectedCountry && (
                  <InputGroup.Text id="basic-addon1">
                    {" "}
                    {selectedCountry}
                  </InputGroup.Text>
                )}
                <Form.Control
                  className="placeholder-style"
                  name="phoneNumber"
                  onKeyDown={handleKeyDown}
                  onPaste={(event) => handlePaste(event)}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                  value={registerdata.phoneNumber}
                  autoComplete="off"
                  maxLength={10}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} className="pb-3">
            <Form.Group>
              <Form.Label className="label-custom">Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="companyName"
                className="placeholder-style"
                onChange={(e) => {
                  handleOnChange(e);
                }}
                value={registerdata.companyName}
                autoComplete="off"
                maxLength={40}
              />
            </Form.Group>
            <span className="error-msg">
              {registerdata.companyName.length === 0 ? formError : ""}
            </span>
          </Col>
          <Col md={6} className="pb-3">
            <Form.Group>
              <Form.Label className="label-custom">Job Title</Form.Label>
              <Form.Control
                type="text"
                name="jobTitle"
                className="placeholder-style"
                onChange={(e) => {
                  handleOnChange(e);
                }}
                value={registerdata.jobTitle}
                autoComplete="off"
                maxLength={20}
                onKeyDown={handleletterKeyDown}
              />
            </Form.Group>
          </Col>
          <Col md={12} className="pb-3">
            <Form.Label className="label-custom">Your Domain</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder=""
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                name="domain"
                autoComplete="off"
                maxLength={30}
                onChange={(e) => {
                  handleChangeDomain(e);
                  handleOnChange(e);
                }}
                value={registerdata.domain}
              />
              <InputGroup.Text id="basic-addon2">
                .tymeplushr.com
                <span className="px-2">
                  {domainstatus === "success" ? (
                    <CheckCircleIcon color="success" />
                  ) : domainstatus === "error" ? (
                    <CancelIcon color="error" />
                  ) : (
                    <></>
                  )}
                </span>
              </InputGroup.Text>
            </InputGroup>
            <span className="error-msg">
              {registerdata.domain.length === 0 ? formError : ""}
            </span>
          </Col>
          <Col md={12}>
            <Form.Group className="pb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="label-custom">
                Number of Employees
              </Form.Label>
              <Form.Control
                autoComplete="off"
                placeholder=""
                name="employees"
                onChange={(e) => {
                  handleOnChange(e);
                }}
                value={registerdata.employees}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
                maxLength={8}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="my-3 label-agree">
            <Form.Check
              type="checkbox"
              id="terms"
              name="terms"
              checked={registerdata.terms}
              onChange={(e) =>
                setRegisterData({ ...registerdata, terms: e.target.checked })
              }
              label={
                <span>
                  By checking this box, you agree to the{" "}
                  <Link
                    className="link"
                    to="/termsandconditions"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Terms and Conditions
                  </Link>{" "}
                  of TymeplusHR
                </span>
              }
            />
            {!registerdata.terms && submitted && (
              <span className="error-msg">
                Please agree to the Terms and Conditions.
              </span>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <button type="submit" className="theme-filldbtn">
              Continue
            </button>
          </Col>
        </Row>

        <div className="text-center pt-4 pb-2 text2">
          <p>
            Existing User?
            <span
              className="color5 cursor-pointer ps-1"
              onClick={() => navigate("/login")}
            >
              Login
            </span>
          </p>
        </div>
      </Form>
    </>
  );
};

export default DetailsForm;
